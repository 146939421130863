.MuiTableRow-root .MuiTableCell-head {
    padding-left: 20px;
    padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-root {
    padding-left: 20px;
    padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-paddingNone {
    padding: 0px;
}

.MuiIconButton-root {
    cursor: pointer;
}

.asset_department {
    background-color: #fff;
    padding: 8px;
    height: auto;
}

.w-60 {
    width: 60%;
}

.margin-auto {
    margin: auto;
}

.none_wrap {
    white-space: nowrap !important;
}

.validator-form-scroll-dialog {
    overflow-y: auto !important;
    display: flex !important;
    flex-direction: column !important;
}

.logo-login{
    max-width: 400px !important;
}
.logo-login img{
    width: 100% !important;
}

.margin-top-12 {
    margin-top: 12px;
}
.flex {
    display: flex;
}
.spaceBetween {
    justify-content: space-between;
}

.colorRed {
    color: red;
}
.btnRefuse {
    color: #fff !important;
    background: #f44336 !important;
}
.btnClose {
    color: #fff !important;
    position: relative;
}
